<template>
  <div>

  </div>

</template>
<script>
import router from '@/router';

export default {
  mounted() {
    router.push(`/historique?success=${false}`);
  },
};

</script>
